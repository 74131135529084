import Vue from 'vue'
import { debounce } from 'lodash'
// import {Message,MessageBox} from 'element-ui'
// v-dialogDrag: 弹窗拖拽
Vue.directive('dialogDrag', {
    bind(el, binding, vnode, oldVnode) {
        const dialogHeaderEl = el.querySelector('.el-dialog__header')
        const dragDom = el.querySelector('.el-dialog')
        dialogHeaderEl.style.cursor = 'move'

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)

        dialogHeaderEl.onmousedown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderEl.offsetLeft
            const disY = e.clientY - dialogHeaderEl.offsetTop

            // 获取到的值带px 正则匹配替换
            let styL, styT

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (sty.left.includes('%')) {
                styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)
                styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)
            } else {
                styL = +sty.left.replace(/\px/g, '')
                styT = +sty.top.replace(/\px/g, '')
            }

            document.onmousemove = function (e) {
                // 通过事件委托，计算移动的距离
                const l = e.clientX - disX
                const t = e.clientY - disY

                // 移动当前元素
                dragDom.style.left = `${l + styL}px`
                dragDom.style.top = `${t + styT}px`

                // 将此时的位置传出去
                // binding.value({x:e.pageX,y:e.pageY})
            }

            document.onmouseup = function (e) {
                document.onmousemove = null
                document.onmouseup = null
            }
        }
    }
})
// 定义一个全局使用字符长度限定的函数
const byteLength = debounce(function (name,maxLimitStr,index,p) {  //获取字符串的字节数，扩展string类型方法
    // let str = null,subStr=null;
    //   if(index==undefined) str = this.ruleForm[name]
    //   else str = this.ruleForm[p][index][name]
    //   if(!str) return false;
    //   subStr=str;
    //   while(byteLengthNum(subStr)>maxLimitStr){
    //       subStr=subStr.substring(0,subStr.length-1);
    //   }
      // if(subStr.length<str.length){
      //     let msg="字符数超过"+maxLimitStr+"：【"+str+"】\n已截断末尾字符：【"+str.substring(subStr.length)+"】";
      //     Message.error(msg)
      // }
      // if(index==undefined)  this.ruleForm[name]=subStr
      // else  this.ruleForm[p][index][name]=subStr

},200)
function byteLengthNum(str) {  //获取字符串的字节数，扩展string类型方法
    if(!str)return 0;
    let str1 = str.trim()
    var b = 0, len = str1.length;  //初始化字节数递加变量并获取字符串参数的字符个数
    if(len) {  //如果存在字符串，则执行计划
        for(var i = 0; i < len; i ++) {  //遍历字符串，枚举每个字符
            let charCode=str1.charCodeAt(i);
            if(
                (charCode >= 0 && charCode <= (parseInt('04FF', 16) - 6)) ||
                (charCode >= parseInt('0600', 16) && charCode <= parseInt('06FF', 16)) ||
                (charCode >= parseInt('0750', 16) && charCode <= parseInt('077F', 16))
            ) {
                b ++;
            }else {
                b += 2;  //则累加2个
            }
        }
        return b;  //返回字节数
    } else {
        return 0;  //如果参数为空，则返回0个
    }
}
// 显示实际长度
Vue.directive('limitWords', {
    update: function (el,binding) {
        setTimeout(() => {
            f_render(el,binding)
        }, 200);
    }
  })

// 渲染函数
function f_render(el,binding) {
    let txt = null,show = null
    if(el.querySelector('textarea')){
        txt = el.querySelector('textarea').value,show = el.querySelector(".el-input__count")
    }
    if(el.querySelector('.el-input__inner')){
        txt = el.querySelector('.el-input__inner').value,show = el.querySelector(".el-input__suffix .el-input__count-inner")
    }
    let num = byteLengthNum(txt),limitNum = binding.value
    if (show){
        if (num > limitNum) {
            show.innerText = num + '/' + limitNum + ' 字符超长！'
            show.setAttribute('style', 'color:red')
        } else {
            show.innerText = num + '/' + limitNum
            show.setAttribute('style', 'color:#909399')
        }
    }
}
  // 校验自动聚焦到第一个元素
export function firstInputFocus(){
    setTimeout(()=>{
            var isError= document.getElementsByClassName("is-error");
            let isErrorInput = isError[0].querySelector('input');
            let isErrortextarea = isError[0].querySelector('.el-textarea__inner');
            let isErrorlabel = isError[0].querySelector('.el-form-item__label');
            if(isErrorInput){
                isErrorInput.focus();
            }
            if(isErrortextarea){
                isErrortextarea.focus();
            }
            if(isErrorlabel){
                isErrorlabel.scrollIntoView({
                    behavior: "smooth"
                });
            }
    },100);
}
Vue.prototype.$maxLength = byteLength
Vue.prototype.$firstInputFocus = firstInputFocus