
    function getNow() {
        let d = new Date();
        let year = d.getFullYear();
        let month = change(d.getMonth() + 1);
        let day = change(d.getDate());

        function change(t) {
            if (t < 10) {
                return "0" + t;
            } else {
                return t;
            }
        }
        let time = year + '年' + month + '月' + day + '日';
        return time;
    }

    export function addMark() {
        const userId = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')).name :'';
        let now = getNow()
        //默认设置
        let settings = {
            watermark_txt: `吉客印-${userId}-${now}`,
            watermark_x: 20, //水印起始位置x轴坐标
            watermark_y: 20, //水印起始位置Y轴坐标
            watermark_rows: 20, //水印行数
            watermark_cols: 20, //水印列数
            watermark_x_space:300, //水印x轴间隔
            watermark_y_space: 150, //水印y轴间隔
            watermark_color: '#aaa', //水印字体颜色
            watermark_alpha: 0.2, //水印透明度
            watermark_fontsize: '15px', //水印字体大小
            watermark_font: '微软雅黑', //水印字体
            watermark_width: 220, //水印宽度
            watermark_height: 80, //水印长度
            watermark_angle: 20 //水印倾斜度数
        };
        let oTemp = document.createElement('div');
        oTemp.style.height = '100%';
        oTemp.style.width = '100%';
        oTemp.style.overflow = 'hidden';
        oTemp.style.position = 'fixed';
        oTemp.style.left = '0';
        oTemp.style.top = '0';
        oTemp.style.pointerEvents = 'none';
        console.log(oTemp);
        //获取页面最大宽度
        // let page_width = Math.max(document.body.clientWidth, document.body.clientWidth);
        // let cutWidth = page_width * 0.0150;
        // page_width = page_width - cutWidth;
        // //获取页面最大高度
        // let page_height = Math.max(document.body.scrollHeight, document.body.clientHeight) + 450;
        // page_height = Math.max(page_height, window.innerHeight - 30);
        // //如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔
        // if (settings.watermark_cols == 0 || (parseInt(settings.watermark_x + settings.watermark_width * settings.watermark_cols + settings.watermark_x_space * (settings.watermark_cols - 1)) > page_width)) {
        //     settings.watermark_cols = parseInt((page_width - settings.watermark_x + settings.watermark_x_space) / (settings.watermark_width + settings.watermark_x_space));
        //     settings.watermark_x_space = parseInt((page_width - settings.watermark_x - settings.watermark_width * settings.watermark_cols) / (settings.watermark_cols - 1));
        // }
        // //如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔
        // if (settings.watermark_rows == 0 || (parseInt(settings.watermark_y + settings.watermark_height * settings.watermark_rows + settings.watermark_y_space * (settings.watermark_rows - 1)) > page_height)) {
        //     settings.watermark_rows = parseInt((settings.watermark_y_space + page_height - settings.watermark_y) / (settings.watermark_height + settings.watermark_y_space));
        //     settings.watermark_y_space = parseInt(((page_height - settings.watermark_y) - settings.watermark_height * settings.watermark_rows) / (settings.watermark_rows - 1));
        // }
        let x;
        let y;
        for (let i = 0; i < settings.watermark_rows; i++) {
            y = settings.watermark_y + (settings.watermark_y_space + settings.watermark_height) * i;
            for (let j = 0; j < settings.watermark_cols; j++) {
                x = settings.watermark_x + (settings.watermark_width + settings.watermark_x_space) * j;
                let mask_div = document.createElement('div');
                mask_div.id = 'mask_div' + i + j;
                mask_div.className = 'mask_div';
                mask_div.appendChild(document.createTextNode(settings.watermark_txt));
                //设置水印div倾斜显示
                mask_div.style.webkitTransform = "rotate(-" + settings.watermark_angle + "deg)";
                mask_div.style.MozTransform = "rotate(-" + settings.watermark_angle + "deg)";
                mask_div.style.msTransform = "rotate(-" + settings.watermark_angle + "deg)";
                mask_div.style.OTransform = "rotate(-" + settings.watermark_angle + "deg)";
                mask_div.style.transform = "rotate(-" + settings.watermark_angle + "deg)";
                mask_div.style.visibility = "";
                mask_div.style.position = "absolute";
                mask_div.style.left = x + 'px';
                mask_div.style.top = y + 'px';
                mask_div.style.overflow = "hidden";
                mask_div.style.zIndex = "9999";
                //让水印不遮挡页面的点击事件
                mask_div.style.pointerEvents = 'none';
                mask_div.style.opacity = settings.watermark_alpha;
                mask_div.style.fontSize = settings.watermark_fontsize;
                mask_div.style.fontFamily = settings.watermark_font;
                mask_div.style.color = settings.watermark_color;
                mask_div.style.textAlign = "center";
                mask_div.style.width = settings.watermark_width + 'px';
                mask_div.style.height = settings.watermark_height + 'px';
                mask_div.style.display = "block";
                oTemp.appendChild(mask_div);
            };
        };
        document.body.appendChild(oTemp);
    }
