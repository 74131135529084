import qs from 'qs';

/*秦彬start*/

// 取出链接中的_user和_token
export function getUserAndToken() {
  let search = window.location.search.substr(1)
  if (search.indexOf('_user') > -1 && search.indexOf('_token') > -1) {
    let arr = search.split('&')
    let _user = arr[0].split('=')[1]
    let _token = arr[1].split('=')[1]
    let params = {
      _user: _user,
      _token: _token
    }
    return params
  }
  return false
}



// 格式化需要用post请求发送的表单数据，最终数据格式为：xxx[yyy]: zzz,其中xxx为后端需要的参数名称，yyy为原始数据的key，zzz为原始数据的value
export function formatFormData(data, paramName) {
  let arr;
  let pData = {};

  arr = qs.stringify(data).split("&");
  for (let i = 0; i < arr.length; i++) {
    let k = arr[i].split('=')[0];
    let v = arr[i].split('=')[1];
    pData[`${paramName}[${k}]`] = v;
  }
  return pData;
}

// 表格高度
export function tableHeight(arr = []) {
  let paginationHeight = document.querySelector('.page') ? parseInt(window.getComputedStyle(document.querySelector('.page'))['height']) : 0

  let tableHeight = document.documentElement.clientHeight - 13 - paginationHeight
  let tableHeightData = [{
      selector: '.container',
      cssProperty: 'paddingTop'
    },
    {
      selector: '.topForm',
      cssProperty: 'height'
    },
    {
      selector: '.tableWrap',
      cssProperty: 'marginTop'
    },
    {
      selector: '.operation-wrapper',
      cssProperty: 'height'
    },
  ]
  if (arr.length > 0) {
    tableHeightData = tableHeightData.concat(arr)
  }
  for (let i = 0; i < tableHeightData.length; i++) {
    if (document.querySelector(tableHeightData[i].selector) != null) {
      let num = parseInt(window.getComputedStyle(document.querySelector(tableHeightData[i].selector))[tableHeightData[i].cssProperty])

      tableHeight -= num
    }
  }
  return tableHeight
}

// 按回车键触发搜索
export function bindKeyUpSearch(func) {
  let tableForm = document.querySelector('.tableForm')
  tableForm.addEventListener('keyup', (e) => {
    if (e.keyCode == 13) {
      let targetNode = e.target.parentNode.parentNode
      if (targetNode.className.indexOf('el-select') == -1) {
        func()
      }
    }
  })
}

// 判断url链接是否是https
export function isHttps() {
  return window.location.href.indexOf('https') > -1 ? true : false
}

/*秦彬end*/


// 毫秒转化为中国标准时间，t=1昨天，类推
export function fnMsToCST(t) {
  return [
    new Date(new Date().getTime() - 3600 * 1000 * 24 * t),
    new Date()
  ];
}

// 中国标准时间转化为年月日时分秒格式
export function fnCSTToYmd(date, opt) {
  // defhms-"00:00:00", "23:59:59"
  function fnAddZero(params) {
    return params = params < 10 ? "0" + params : params;
  }
  let y = date.getFullYear(),
    m = date.getMonth() + 1,
    d = date.getDate(),
    h = date.getHours(),
    minute = date.getMinutes(),
    second = date.getSeconds();
  if (opt && opt.tag) {
    return y + "-" + fnAddZero(m) + "-" + fnAddZero(d);
  } else if (opt && opt.defhms) {
    return y + "-" + fnAddZero(m) + "-" + fnAddZero(d) + " " + opt.defhms;
  } else {
    return y + "-" + fnAddZero(m) + "-" + fnAddZero(d) + " " + fnAddZero(h) + ":" + fnAddZero(minute) + ":" + fnAddZero(second);
  }

}

// 郑亚杰start
//获取今天0点到现在的毫秒数
function getMsecond() {
  var now = new Date().getTime();
  var zero = new Date(new Date().getFullYear() + "/" + ((new Date().getMonth()) / 1 + 1) + "/" + new Date().getDate()).getTime();
  // console.log(now - zero);
  return now - zero
}
export let pickerOptions = {
  shortcuts: [{
      text: '今日',
      onClick(picker) {
        var date = new Date().getFullYear() + "/" + ((new Date().getMonth()) / 1 + 1) + "/" + new Date().getDate();
        picker.$emit('pick', [new Date(date), new Date()]);
      }
    },
    {
      text: '昨日',
      onClick(picker) {
        var date = new Date().setTime(new Date().getTime() - (3600 * 1000 * 24 + getMsecond()));
        var date1 = new Date().setTime(new Date().getTime() - getMsecond() - 1);
        picker.$emit('pick', [new Date(date), new Date(date1)]);
      }
    },
    {
      text: '前天',
      onClick(picker) {
        var date = new Date().setTime(new Date().getTime() - (3600 * 1000 * 24 * 2 + getMsecond()));
        var date1 = new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 1 - getMsecond() - 1);
        picker.$emit('pick', [new Date(date), new Date(date1)]);
      }
    },
    {
      text: '本月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(new Date().getTime() - (getMsecond()));
        start.setDate(1);
        picker.$emit('pick', [start, new Date()]);
      }
    },
    {
      text: "近3天",
      onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setDate(end.getDate() - 2);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '近7天',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * (24 * (7 - 1)));
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '近15天',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * (24 * (15 - 1)));
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '近1个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setMonth(start.getMonth() - 1);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '近3个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setMonth(start.getMonth() - 3);
        picker.$emit('pick', [start, end]);
      }
    }
  ]
}
// 广告数据概览
export let adPickerOptions = {
  disabledDate(time) {
    return time.getTime() > Date.now();
  },
  shortcuts: [
    {
      text: '今天',
      quick:true,
      onClick(picker) {
        var date = new Date().getFullYear() + "/" + ((new Date().getMonth()) / 1 + 1) + "/" + new Date().getDate();
        if(picker){
          picker.$emit('pick', [new Date(date), new Date()]);
        }else{
          return [new Date(date), new Date()]
        }
      }
    },
    {
      text: '昨天',
      quick:true,
      onClick(picker) {
        var date = new Date().setTime(new Date().getTime() - (3600 * 1000 * 24 + getMsecond()));
        var date1 = new Date().setTime(new Date().getTime() - getMsecond() - 1);
        if(picker){
          picker.$emit('pick', [new Date(date), new Date(date1)]);
        }else{
          return [new Date(date), new Date(date1)]
        }
        
      }
    },
    {
      text: "近3天",
      quick:true,
      onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * (24 * (3 - 1)));
        if(picker){
          picker.$emit('pick', [start, end]);
        }else{
          return [start, end]
        }
      }
    },
    {
      text: '近7天',
      quick:true,
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * (24 * (7 - 1)));
        if(picker){
          picker.$emit('pick', [start, end]);
        }else{
          return [start, end]
        }
      }
    },
    {
      text: '本周',
      onClick(picker) {
        let now = new Date(),
          d = now.getDay(),
          start,
          end;
        start = new Date(now.getTime() - getMsecond() - 3600 * 24 * (d) * 1000)
        if(picker){
          picker.$emit('pick', [start, new Date()]);
        }else{
          return [start, new Date()]
        }
      }
    },
    {
      text: '本月',
      quick:true,
      onClick(picker) {
        let now = new Date(),
          y = now.getFullYear(),
          m = now.getMonth(),
          start = new Date(y, m, 1),
          end = new Date(y, m + 1, 0);
        if(picker){
          picker.$emit('pick', [start, new Date()]);
        }else{
          return [start, new Date()]
        }
      }
    },
    {
      text: '上周',
      onClick(picker) {
        let now = new Date(),
          d = now.getDay(),
          start,
          end;
        start = new Date(now.getTime() - getMsecond() - 3600 * 24 * (d  + 7) * 1000)
        end = new Date(now.getTime() - getMsecond() - 3600 * 24 * (d) * 1000);
        if(picker){
          picker.$emit('pick', [start, end]);
        }else{
          return [start, end]
        }
      }
    },
    {
      text: '上月',
      onClick(picker) {
        let now = new Date(),
          y = now.getFullYear(),
          m = now.getMonth(),
          start = new Date(y, m - 1, 1),
          end = new Date(y, m, 0);
          if(picker){
            picker.$emit('pick', [start, end]);
          }else{
            return [start, end]
          }
      }
    },
    // {
    //   text: "近5天",
    //   quick:true,
    //   onClick(picker) {
    //     var end = new Date();
    //     var start = new Date();
    //     start.setTime(start.getTime() - 3600 * 1000 * (24 * (5 - 1)));
    //     if(picker){
    //       picker.$emit('pick', [start, end]);
    //     }else{
    //       return [start, end]
    //     }
    //   }
    // },
    // {
    //   text: '上月+本月',
    //   quick:true,
    //   onClick(picker) {
    //     let now = new Date(),
    //         y = now.getFullYear(),
    //         m = now.getMonth(),
    //         start = new Date(y, m - 1, 1)
    //     const end = new Date();
    //     if(picker){
    //       picker.$emit('pick', [start, end]);
    //     }else{
    //       return [start, end]
    //     }
    //   }
    // },
    {
      text: '近30天',
      quick:true,
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * (24 * (30 - 1)));
        if(picker){
          picker.$emit('pick', [start, end]);
        }else{
          return [start, end]
        }
      }
    },
    {
      text: '近90天',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * (24 * (90 - 1)));
        if(picker){
          picker.$emit('pick', [start, end]);
        }else{
          return [start, end]
        }
      }
    }
  ]
}
export function objectDeepEqual(obj1, obj2) {
  if (!obj1 && !obj2){
    return true
  }
  if (!obj1 || !obj2) {
    return false
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  } else {
    for (let key in obj1) {
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }
      //类型相同
      if (typeof obj1[key] === typeof obj2[key]) {
        //同为引用类型
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const equal = objectDeepEqual(obj1[key], obj2[key]);
          if (!equal) {
            return false;
          }
        }
        //同为基础数据类型
        if (typeof obj1[key] !== 'object' && typeof obj2[key] !== 'object' && obj1[key] !== obj2[key]) {
          return false;
        }
      }
      else {
        return false;
      }
    }
  }
  return true;
};
export function getTextSize(text, fontSize) {
  let span = document.createElement('span');
  let result = {};
  result.width = span.offsetWidth;
  result.height = span.offsetHeight;
  span.style.visibility = 'hidden';
  span.style.fontSize = fontSize + 'px';
  document.body.appendChild(span);
  if (typeof span.textContent != 'undefined') {
    span.textContent = text;
  } else {
    span.innerText = text;
  }
  result.width = span.offsetWidth - result.width;
  result.height = span.offsetHeight - result.height;
  span.parentNode.removeChild(span);
  return result;
}

// 验证网域正则
export let domainReg = /([a-z0-9--]{1,200})\.(ac.cn|bj.cn|sh.cn|tj.cn|cq.cn|he.cn|sn.cn|sx.cn|nm.cn|ln.cn|jl.cn|hl.cn|js.cn|zj.cn|ah.cn|fj.cn|jx.cn|sd.cn|ha.cn|hb.cn|hn.cn|gd.cn|gx.cn|hi.cn|sc.cn|gz.cn|yn.cn|gs.cn|qh.cn|nx.cn|xj.cn|tw.cn|hk.cn|mo.cn|xz.cn|com.cn|net.cn|org.cn|gov.cn|我爱你|在线|中国|网址|网店|中文网|公司|网络|集团|com|cn|cc|org|net|xin|xyz|vip|shop|top|club|wang|fun|info|online|tech|store|site|ltd|ink|biz|group|link|work|pro|mobi|ren|kim|name|tv|red|cool|team|live|pub|company|zone|today|video|art|chat|gold|guru|show|life|love|email|fund|city|plus|design|social|center|world|auto)/